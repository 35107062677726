<template>
  <v-row v-if="notice">
    <v-col cols="12">
      <v-card>
        <v-card-title>
          <v-icon class="mr-2">
            mdi-bullhorn
          </v-icon>
          <h4 class="text-truncate">
            {{ notice.title }}
          </h4>
        </v-card-title>
        <v-card-subtitle class="d-flex py-5">
          <v-icon class="text-subtitle-1 mr-1">
            mdi-account
          </v-icon>
          <span class="text-subtitle-1">
            {{ notice.users ? notice.users.username : '' }}
          </span>
          <v-spacer />
          <v-icon class="text-subtitle-1 mr-1" :style="{ top: '-2px' }">
            mdi-clock-time-four-outline
          </v-icon>
          <span v-if="new Date().getTime() - notice.registerDate < 1000 * 60 * 60 * 7">
            {{ notice.registerDate | moment('from', 'now') }}
          </span>
          <span v-else>
            {{ notice.registerDate | moment('YYYY.MM.DD. HH:mm:ss') }}
          </span>
        </v-card-subtitle>
        <v-card-text id="notice-content" v-html="notice.content" />
        <v-card-actions>
          <v-spacer />
          <v-dialog
            v-if="notice.users ? $store.state.user.user.idx === notice.users.idx && ($store.state.user.user.userAuth === 'M' || $store.state.user.user.userAuth === 'A') : false"
            v-model="showEdit"
            width="800px"
            :fullscreen="mobile"
            :transition="mobile ? 'dialog-bottom-transition' : 'scale-transition'"
          >
            <template #activator="{ attrs, on }">
              <v-btn
                large
                v-bind="attrs"
                v-on="on"
              >
                수정
              </v-btn>
            </template>
            <NoticeEdit :notice="notice" @close-dialog="showEdit = !showEdit" />
          </v-dialog>
          <v-btn
            v-if="notice.users ? $store.state.user.user.idx === notice.users.idx && ($store.state.user.user.userAuth === 'M' || $store.state.user.user.userAuth === 'A') : false"
            large
            @click="deleteNotice"
          >
            삭제
          </v-btn>
          <v-btn
            large
            @click="$router.go(-1)"
          >
            뒤로가기
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import NoticeEdit from '../Edit'
  export default {
    name: 'NoticeViewContent',
    components: { NoticeEdit },
    props: [
      'notice',
      'params',
    ],
    data () {
      return {
        showEdit: false,
      }
    },
    computed: {
      mobile () {
        return this.$isMobile
      },
    },
    methods: {
      async deleteNotice () {
        const url = `/manage/notice/${this.notice.idx}`
        await this.$axios.delete(url).then(res => {
          if (res && res.data && res.data.status === 'SUCCESS') {
            alert('공지사항이 삭제되었습니다.')
            this.$router.go(-1)
          } else {
            alert(res.data.message ? res.data.message : '오류가 발생하였습니다.')
          }
        }).catch(e => {
          alert('오류가 발생하였습니다.')
        })
      },
    },
  }
</script>

<style scoped>

</style>
