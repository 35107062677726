<template>
  <v-lazy
    :options="{
      threshold: .5
    }"
  >
    <v-container>
      <NoticeViewHeader />
      <NoticeViewContent :notice="notice" />
    </v-container>
  </v-lazy>
</template>

<script>
  import NoticeViewHeader from '@/views/notice/view/Header'
  import NoticeViewContent from '@/views/notice/view/Content'
  export default {
    name: 'NoticeView',
    components: { NoticeViewContent, NoticeViewHeader },
    data () {
      return {
        notice: {},
      }
    },
    computed: {
      noticeIdx () {
        return this.$route.params.noticeIdx
      },
    },
    beforeMount () {
      this.getNotice()
    },
    methods: {
      getNotice () {
        const url = `/notice/${this.noticeIdx}`
        this.$axios.get(url)
          .then(res => {
            if (res && res.data) {
              this.notice = res.data
            }
          })
          .catch(err => {
            console.log(err)
          })
      },
    },
  }
</script>

<style scoped>

</style>
