<template>
  <v-card>
    <v-card-title class="pt-10 pt-md-3">
      공지사항 수정
    </v-card-title>
    <v-card-text>
      <v-text-field
        v-model="thisNotice.title"
        label="Title"
      />
      <Editor ref="editor" :content="thisNotice.content" />
    </v-card-text>
    <v-card-actions
      class="mt-10"
      :class="{
        'justify-end': !mobile,
        'd-flex': mobile,
        'pt-15': mobile,
        'flex-wrap': mobile
      }"
    >
      <v-btn
        large
        class="text-body-1"
        :class="{ 'mx-0': mobile, 'mb-2': mobile }"
        :width="mobile ? '100%' : ''"
        @click="saveNotice"
      >
        저장하기
      </v-btn>
      <v-btn
        large
        class="text-body-1"
        :class="{ 'mx-0': mobile }"
        :width="mobile ? '100%' : ''"
        @click="close"
      >
        창닫기
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import Editor from '@/components/core/Editor'
  export default {
    name: 'NoticeEdit',
    components: { Editor },
    props: [
      'notice',
    ],
    data () {
      return {
        title: '',
      }
    },
    computed: {
      thisNotice () {
        return this.notice
      },
      content () {
        return this.$refs.editor.getContent()
      },
      mobile () {
        return this.$isMobile
      },
    },
    methods: {
      close () {
        this.$emit('close-dialog')
      },
      async saveNotice () {
        const url = `/manage/notice/${this.notice.idx}`
        this.thisNotice.content = this.$refs.editor.getContent()
        await this.$axios.patch(url, this.thisNotice).then(res => {
          if (res && res.data && res.data.status === 'SUCCESS') {
            alert('공지사항이 수정되었습니다.')
            this.close()
            window.scrollTo(0, 0)
          } else {
            alert(res.data.message)
          }
        }).catch(e => {
          alert('오류가 발생하였습니다.')
        })
      },
    },
  }
</script>

<style scoped>

</style>
